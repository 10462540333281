<template>
  <div class="m-3 p-2 text-left border rounded">
    <div class="pb-3 font-bold text-center">About the app</div>
    <p class="text-justify">
      It is an example API project that allows an estate agent to show records of when and to which client their
      employees will show their appointments. This API project shows how long the employees go to and from the
      appointments, makes it possible to check the time they allocate for the appointment and all these appointments
      without conflicts among themselves.
    </p>
  </div>
</template>

<script setup>

</script>
